
.quantite-widget{

}
.quantite-widget button.btn-action{
  border-radius: 24px;
  background-color: #8886e7;
  color: #FFFFFF;
  font-weight: bold;
  font-size: 1.1em;
  height: 22px;
  width: 22px;
  padding: 0;
  margin: 0 5px;
  text-align: center;
  display: inline-block;
  border-color: #8886e7;
  box-shadow: none;
}
.quantite-widget button.btn-action:hover{
  background-color: #343379;
  border-color: #343379;
}

.quantite-widget span.quantite-value{
  font-size: 1.2em;
}