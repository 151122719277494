main#loading-bar-request {
  margin: 1px auto;
  position: relative;
  display: block;
  width: 100%; 
}

.loading-bar-container {
  height: 2px;
  width: 100%;
  background-color: #cfe0f0;
  position: absolute;
  overflow: hidden;
}

.loading-bar {
  height: 100%;
  width: 50%;
  background-color: #ffa200;
  position: absolute;
  left: -50%;
  animation: loading 2.0s ease-in 0.2s infinite;
}

@keyframes loading {
 0% {
  transform:translateX(0)
 }
 to {
  transform:translateX(400%)
 }
}